/* eslint-disable react-refresh/only-export-components */
import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  useRouteError
} from 'react-router-dom';
import { BaseLayout, DetailLayout } from '@sagano/admin/layouts';
import PrivateRoute from './PrivateRoute';

const Notification = lazy(() => import('@sagano/admin/views/Notification'));
const BookingList = lazy(() => import('@sagano/admin/views/Booking/List'));
const BookingCreate = lazy(() => import('@sagano/admin/views/Booking/Create'));
const BookingConfirm = lazy(
  () => import('@sagano/admin/views/Booking/Confirm')
);

const BookingDetail = lazy(() => import('@sagano/admin/views/Booking/Detail'));
const BookingUpdate = lazy(() => import('@sagano/admin/views/Booking/Update'));
const BookingHistory = lazy(
  () => import('@sagano/admin/views/Booking/History')
);
const BookingCancel = lazy(() => import('@sagano/admin/views/Booking/Cancel'));
const AssignSeats = lazy(
  () => import('@sagano/admin/views/Booking/AssignSeats')
);
const EnsureSeats = lazy(
  () => import('@sagano/admin/views/Booking/EnsureSeats')
);

const ChangePassword = lazy(() => import('@sagano/admin/views/ChangePassword'));
const Dashboard = lazy(() => import('@sagano/admin/views/Dashboard'));
const Message = lazy(() => import('@sagano/admin/views/Message'));
const Login = lazy(() => import('@sagano/admin/views/Login'));
const NotFound = lazy(() => import('@sagano/share/views/NotFound'));

const Agents = lazy(() => import('@sagano/admin/views/Agent/List'));
const AgentDetail = lazy(() => import('@sagano/admin/views/Agent/Detail'));
const AgentUpdate = lazy(() => import('@sagano/admin/views/Agent/Update'));
const GroupDetail = lazy(() => import('@sagano/admin/views/Agent/Group'));

const BasicInfo = lazy(() => import('@sagano/admin/views/BasicInfo'));
const BankAccounts = lazy(() => import('@sagano/admin/views/BankAccounts'));
const Services = lazy(() => import('@sagano/admin/views/Services'));
const PatternCalendar = lazy(
  () => import('@sagano/admin/views/PatternCalendar')
);
const SpecialDays = lazy(() => import('@sagano/admin/views/SpecialDays'));
const ArrangementApplication = lazy(
  () => import('@sagano/admin/views/ArrangementApplication/List')
);
const AddArrangementApplication = lazy(
  () => import('@sagano/admin/views/ArrangementApplication/Add')
);
const EditArrangementApplication = lazy(
  () => import('@sagano/admin/views/ArrangementApplication/Detail')
);

const FreeCountPlans = lazy(() => import('@sagano/admin/views/FreeCountPlans'));
const GroupPricingPlans = lazy(
  () => import('@sagano/admin/views/GroupPricingPlans')
);
const GroupSchedules = lazy(() => import('@sagano/admin/views/GroupSchedules'));
const SpecialContractTerms = lazy(
  () => import('@sagano/admin/views/SpecialContractTerms')
);
const Approvals = lazy(() => import('@sagano/admin/views/Approvals'));

const Users = lazy(() => import('@sagano/admin/views/Users'));
const ResetPassword = lazy(() => import('@sagano/admin/views/ResetPassword'));

const SearchAllotments = lazy(
  () => import('@sagano/admin/views/SearchAllotments')
);
const SearchAllotmentsByDate = lazy(
  () => import('@sagano/admin/views/SearchAllotments/ByDate')
);
const BookingListByStatus = lazy(
  () =>
    import('@sagano/admin/views/SearchAllotments/ByDate/BookingListByStatus')
);

const ArrangementPatterns = lazy(
  () => import('@sagano/admin/views/ArrangementPattern/List')
);
const AddArrangementPattern = lazy(
  () => import('@sagano/admin/views/ArrangementPattern/Add')
);
const EditArrangementPattern = lazy(
  () => import('@sagano/admin/views/ArrangementPattern/Detail')
);

const InventoryArrangements = lazy(
  () => import('@sagano/admin/views/InventoryArrangements')
);
const OperateSuspended = lazy(
  () => import('@sagano/admin/views/OperateSuspended')
);
const OperationLogs = lazy(() => import('@sagano/admin/views/OperateLogs'));
const Report = lazy(() => import('@sagano/admin/views/Report'));

const Sales = lazy(() => import('@sagano/admin/views/Sales'));
const MonthlyReport = lazy(() => import('@sagano/admin/views/MonthlyReport'));
const MonthlyReportByAgent = lazy(
  () => import('@sagano/admin/views/MonthlyReport/ByAgent')
);
const PaymentByGroup = lazy(
  () => import('@sagano/admin/views/MonthlyReport/PaymentByGroup')
);
const BulkOperation = lazy(() => import('@sagano/admin/views/BulkOperation'));

function RootErrorBoundary() {
  const error = useRouteError() as Error;
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    location.reload();
  }
  return null;
}

const lazyView = (view: JSX.Element) => {
  return <Suspense fallback="Loading...">{view}</Suspense>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RootErrorBoundary />}>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard">
            <Route index element={lazyView(<Dashboard />)} />
            <Route path=":messageId" element={lazyView(<Message />)} />
          </Route>

          <Route path="notifications" element={lazyView(<Notification />)} />
          <Route path="bookings">
            <Route index element={lazyView(<BookingList />)} />
            <Route path="confirm" element={lazyView(<BookingConfirm />)} />
            <Route path="create" element={lazyView(<BookingCreate />)} />
            <Route path=":bookingId" element={<DetailLayout />}>
              <Route index element={lazyView(<BookingDetail />)} />
              <Route path="update" element={lazyView(<BookingUpdate />)} />
              <Route path="cancel" element={lazyView(<BookingCancel />)} />
              <Route path="copy" element={lazyView(<BookingCreate />)} />
              <Route path="assign-seats" element={lazyView(<AssignSeats />)} />
              <Route path="ensure-seats" element={lazyView(<EnsureSeats />)} />
              <Route path="histories" element={lazyView(<BookingHistory />)} />
            </Route>
          </Route>
          <Route path="bulk-operation" element={lazyView(<BulkOperation />)} />
          <Route path="dashboard" element={lazyView(<Dashboard />)} />
          <Route path="basic-info" element={lazyView(<BasicInfo />)} />
          <Route path="bank-accounts" element={lazyView(<BankAccounts />)} />
          <Route path="services" element={lazyView(<Services />)} />
          <Route
            path="pattern-calendar"
            element={lazyView(<PatternCalendar />)}
          />
          <Route path="special-days" element={lazyView(<SpecialDays />)} />
          <Route
            path="inventory-arrangements"
            element={lazyView(<InventoryArrangements />)}
          />
          <Route path="suspended" element={lazyView(<OperateSuspended />)} />
          <Route path="operation-logs" element={lazyView(<OperationLogs />)} />
          <Route path="arrangement-application">
            <Route index element={lazyView(<ArrangementApplication />)} />
            <Route
              path=":serviceTermId/add"
              element={lazyView(<AddArrangementApplication />)}
            />
            <Route
              path=":serviceTermId/:applicationTermId"
              element={lazyView(<EditArrangementApplication />)}
            />
          </Route>
          <Route path="arrangement-pattern">
            <Route index element={lazyView(<ArrangementPatterns />)} />
            <Route path="add" element={lazyView(<AddArrangementPattern />)} />
            <Route
              path=":arrangementPatternId"
              element={lazyView(<EditArrangementPattern />)}
            />
          </Route>
          <Route
            path="free-count-plans"
            element={lazyView(<FreeCountPlans />)}
          />
          <Route
            path="group-pricing-plans"
            element={lazyView(<GroupPricingPlans />)}
          />
          <Route
            path="group-schedules"
            element={lazyView(<GroupSchedules />)}
          />
          <Route
            path="special-contract-terms"
            element={lazyView(<SpecialContractTerms />)}
          />
          <Route path="approvals" element={lazyView(<Approvals />)} />
          <Route path="agents" element={lazyView(<Agents />)} />
          <Route path="agents/:agentId">
            <Route index element={lazyView(<AgentDetail />)} />
            <Route path="update" element={lazyView(<AgentUpdate />)} />
            <Route path="groups/:groupId" element={lazyView(<GroupDetail />)} />
          </Route>
          <Route path="search-allotments">
            <Route index element={lazyView(<SearchAllotments />)} />
            <Route path=":date">
              <Route index element={lazyView(<SearchAllotmentsByDate />)} />
              <Route
                path=":serviceId"
                element={lazyView(<BookingListByStatus />)}
              />
            </Route>
          </Route>
          <Route path="report" element={lazyView(<Report />)} />
          <Route path="sales" element={lazyView(<Sales />)} />
          <Route path="monthly-report">
            <Route index element={lazyView(<MonthlyReport />)} />
            <Route
              path="payment/:groupId"
              element={lazyView(<PaymentByGroup />)}
            />
            <Route
              path=":agentId"
              element={lazyView(<MonthlyReportByAgent />)}
            />
          </Route>
          <Route path="users" element={lazyView(<Users />)} />
          <Route
            path="change-password"
            element={lazyView(<ChangePassword />)}
          />
          <Route path="*" element={lazyView(<NotFound />)} />
        </Route>
      </Route>
      <Route path="/login" element={lazyView(<Login />)} />
      <Route path="/reset-password" element={lazyView(<ResetPassword />)} />
      <Route path="*" element={lazyView(<NotFound />)} />
    </Route>
  )
);

export default router;
