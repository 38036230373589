import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Menu } from '@linktivity/link-ui';
import { useStore } from '@sagano/admin/stores';
import { isProd } from '@sagano/admin/utils/env';
import styles from './sidebar.module.css';

const Sidebar = observer(() => {
  const { t } = useTranslation();
  const { auth } = useStore();

  return (
    <aside className={styles.sidebar}>
      <div className={styles.inner}>
        <Menu className={styles.menu} accordion>
          <Menu.MenuItem label={t('share.dashboard.title')} to="/dashboard" />
          {auth.isStation ? (
            <Menu.SubMenu
              label={t('layouts.sidebar.groupManager')}
              name="booking"
            >
              <Menu.MenuItem label={t('views.booking.title')} to="/bookings" />
            </Menu.SubMenu>
          ) : (
            <>
              <Menu.SubMenu
                label={t('layouts.sidebar.groupManager')}
                name="booking"
              >
                <Menu.MenuItem
                  label={t('share.notification.title')}
                  to="/notifications"
                />
                <Menu.MenuItem
                  label={t('views.booking.title')}
                  to="/bookings"
                />
                <Menu.MenuItem
                  label={t('share.layouts.sidebar.seatInquiry')}
                  to="/search-allotments"
                />
                <Menu.MenuItem
                  label={t('views.bulkOperation.title')}
                  to="/bulk-operation"
                />
              </Menu.SubMenu>
              <Menu.SubMenu label={t('layouts.sidebar.groupSalesManager')}>
                <Menu.MenuItem label={t('views.sales.title')} to="/sales" />
                {!isProd && (
                  <Menu.MenuItem
                    label={t('share.monthlyReport.title')}
                    to="/monthly-report"
                  />
                )}
              </Menu.SubMenu>
              <Menu.SubMenu
                label={t('layouts.sidebar.serviceManager')}
                name="serviceManager"
              >
                <Menu.MenuItem
                  to="inventory-arrangements"
                  label={t('views.inventoryArrangements.title')}
                />
                <Menu.MenuItem label={t('views.report.title')} to="report" />
                <Menu.MenuItem
                  label={t('views.suspended.title')}
                  to="suspended"
                />
                <Menu.MenuItem
                  label={t('views.operationLogs.title')}
                  to="operation-logs"
                />
              </Menu.SubMenu>
              <Menu.SubMenu
                label={t('layouts.sidebar.accountManager')}
                name="accountManager"
              >
                {auth.isBackOfficeAdmin && (
                  <Menu.MenuItem label={t('views.users.title')} to="/users" />
                )}
                <Menu.MenuItem
                  label={t('layouts.sidebar.agent')}
                  to="/agents"
                />
                <Menu.MenuItem
                  label={t('share.password.changePassword')}
                  to="/change-password"
                />
              </Menu.SubMenu>
              <Menu.SubMenu
                label={t('layouts.sidebar.masterManager')}
                name="masterManager"
              >
                <Menu.MenuItem
                  label={t('views.basicInfo.title')}
                  to="/basic-info"
                />
                <Menu.MenuItem
                  label={t('views.bankAccounts.title')}
                  to="/bank-accounts"
                />
                <Menu.MenuItem
                  label={t('views.services.title')}
                  to="/services"
                />
                <Menu.MenuItem
                  label={t('views.patternCalendar.title')}
                  to="/pattern-calendar"
                />
                <Menu.MenuItem
                  label={t('views.specialDays.title')}
                  to="/special-days"
                />
                <Menu.MenuItem
                  label={t('views.arrangementApplication.title')}
                  to="/arrangement-application"
                />
                <Menu.MenuItem
                  label={t('views.arrangementPattern.title')}
                  to="/arrangement-pattern"
                />
                <Menu.MenuItem
                  label={t('views.freeCountPlans.title')}
                  to="/free-count-plans"
                />
                <Menu.MenuItem
                  label={t('views.groupPricingPlans.title')}
                  to="/group-pricing-plans"
                />
                <Menu.MenuItem
                  label={t('views.groupSchedules.title')}
                  to="/group-schedules"
                />
                <Menu.MenuItem
                  label={t('views.specialContractTerms.title')}
                  to="/special-contract-terms"
                />

                <Menu.MenuItem
                  label={t('views.approvals.title')}
                  to="/approvals"
                />
              </Menu.SubMenu>
            </>
          )}
        </Menu>
      </div>
    </aside>
  );
});

export default Sidebar;
