import { AdminMixer, CommonMixer } from '@linktivity/sagano';
const DirectionId = {
  Down: 'down',
  Up: 'up'
} as const;
const ReportTypes = {
  Booking: 'booking',
  Service: 'service',
  Agent: 'agent',
  Prepaid: 'prepaid'
};
const INgReasons = {
  Full: 'FULL',
  ServicedaySuspension: 'SERVICEDAY_SUSPENSION',
  ServiceSuspension: 'SERVICE_SUSPENSION',
  Other: 'OTHER'
} as const;
const BulkOperationType = {
  ReserveAllotments: 'reserveAllotments',
  ConfirmBooking: 'confirmBooking'
} as const;
type BulkOperationType =
  (typeof BulkOperationType)[keyof typeof BulkOperationType];
const FILE_CSV_TYPE = 'text/csv';
const FILE_PDF_TYPE = 'application/pdf';

const OperationCategory = AdminMixer.OperationCategory;
type OperationCategory = AdminMixer.OperationCategory;
type INgReasons = (typeof INgReasons)[keyof typeof INgReasons];
const ServicePattern = AdminMixer.ServicePattern;
type ServicePattern = AdminMixer.ServicePattern;

const ApprovalStatus = AdminMixer.GetApprovalRespStatus;
type ApprovalStatus = AdminMixer.GetApprovalRespStatus;

const ApprovalTarget = AdminMixer.ApprovalTarget;
type ApprovalTarget = AdminMixer.ApprovalTarget;

const UnitAnnotationId = AdminMixer.UnitAnnotationId;
type UnitAnnotationId = AdminMixer.UnitAnnotationId;

const FareTypeId = AdminMixer.FareTypeId;
type FareTypeId = AdminMixer.FareTypeId;

const SegmentId = AdminMixer.SegmentId;
type SegmentId = AdminMixer.SegmentId;

const BookingStatus = AdminMixer.SearchServiceOrdersByBookingParamsStatusEnum;
type BookingStatus = AdminMixer.SearchServiceOrdersByBookingParamsStatusEnum;

const GroupTypeId = AdminMixer.GroupTypeId;
type GroupTypeId = AdminMixer.GroupTypeId;

const UserGroup = AdminMixer.UserGroup;
type UserGroup = AdminMixer.UserGroup;

const UserType = AdminMixer.UserType;
type UserType = AdminMixer.UserType;

const Role = CommonMixer.Role;
type Role = CommonMixer.Role;

const BankAccountType = AdminMixer.AccountType;
type BankAccountType = AdminMixer.AccountType;

const MailTemplateEnum = AdminMixer.GetMailTemplateMailTemplateEnum;
type MailTemplateEnum = AdminMixer.GetMailTemplateMailTemplateEnum;
const TrainservicemanServiceStatus = AdminMixer.TrainservicemanServiceStatus;
type TrainservicemanServiceStatus = AdminMixer.TrainservicemanServiceStatus;
const TrainservicemanOperation = AdminMixer.TrainservicemanOperation;
type TrainservicemanOperation = AdminMixer.TrainservicemanOperation;
export {
  DirectionId,
  ReportTypes,
  ServicePattern,
  ApprovalStatus,
  ApprovalTarget,
  UnitAnnotationId,
  FareTypeId,
  SegmentId,
  BookingStatus,
  GroupTypeId,
  UserGroup,
  UserType,
  Role,
  BankAccountType,
  MailTemplateEnum,
  INgReasons,
  TrainservicemanServiceStatus,
  TrainservicemanOperation,
  OperationCategory,
  FILE_CSV_TYPE,
  FILE_PDF_TYPE,
  BulkOperationType
};
